.serviceWrapper {
  width: 367px;
  height: 204px;
  position: relative;
  box-shadow: 0px 4px 24px 0px #00000026;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background-color: #fff;

  .description {
    display: none;
    font-size: 16px;
    line-height: 22.4px;
    padding: 16px;
    margin: 0;
    text-align: left;
    transition: 0.5s;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 204px;
    background: linear-gradient(
      256.54deg,
      #110c13 22.19%,
      #4f1222 45.99%,
      #26424e 84.9%
    );
    transform: translate(0%, -100%) skew(0deg);
    transition: 0.5s;
  }

  .icon {
    padding: 24px 0 71px 24px;
  }

  .titleWrapper {
    width: 200px;
    padding-left: 24px;

    .border {
      width: 70px;
      height: 4px;
      background: linear-gradient(
        256.54deg,
        #110c13 22.19%,
        #4f1222 45.99%,
        #26424e 84.9%
      );
      margin: 0 0 4px 0;
    }

    .title {
      font-family: "Poppins Regular", sans-serif;
      font-size: 16px;
      letter-spacing: -0.32px;
      display: block;
      text-align: left;
    }
  }

  &:hover {
    &::before {
      transform: translate(0%, 0) skew(0deg);
    }

    .description {
      color: #fff;
      display: block;
      position: absolute;
      animation: fadeInAndShrink 0.5s ease-in forwards;
    }

    .titleWrapper,
    .title {
      animation: fadeOutAndShrink 0.5s ease-out forwards;
    }
  }
}

@keyframes fadeOutAndShrink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInAndShrink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 860px) {
  .serviceWrapper {
    .titleWrapper {
      .title {
        font-size: 14px;
      }
    }
  }
}
