.main {
  width: 1145px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 64px;
}

@media (max-width: 1200px) {
  .main {
    width: unset;
  }
}