.footer {
  background-color: #282b35;
  height: 389px;

  .content {
    width: 1145px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .telegramTitle {
      display: block;
    }

    p,
    span {
      margin: 0;
      color: #fff;
      font-size: 16px;
      line-height: 24px;
    }

    .telegramLink {
      display: block;
    }

    a {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
    }

    .emails {
      padding-bottom: 20px;
    }

    .wrapper {
      display: flex;
      align-items: flex-start;
    }

    .contacts {
      position: relative;
      padding-left: 23px;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 162px;
        opacity: 15%;
        background-color: #d9d9d9;
      }
    }

    .img {
      margin-right: 27px;
    }

    .footerImg {
      margin-top: 25px;
      opacity: 0.5;
    }

    .txt {
      font-size: 48px;
      color: #fff;
      font-family: "Playfair Display SemiBold", sans-serif;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    padding: 32px 15px 32px 15px;
  }
}

@media (max-width: 820px) {
  .footer {
    height: unset;

    .content {
      flex-direction: column;

      .contacts {
        &::before {
          height: 195px;
        }
      }

      .img {
        width: 88px;
        height: 26px;
        margin-bottom: 31px;
      }

      .txt {
        font-size: 32px;
        line-height: 35.2px;
      }

      span,
      a,
      p {
        line-height: 21px;
        font-size: 14px;
      }

      span,
      a {
        margin-bottom: 15px;
      }

      .wrapper {
        flex-direction: column;
      }
    }
  }
}
