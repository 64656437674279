.container {
  width: 1145px;
  max-width: 100%;
  box-shadow: 0px 4px 24px 0px #00000026;
  height: 100%;
  display: flex;
  padding: 21px 0 11px 0;
  font-family: "Poppins Regular", sans-serif;
  align-items: center;

  .logoWrapper {
    padding: 0 53px 0 45px;
    display: flex;
    justify-content: center;
  }

  .content {
    position: relative;
    padding-left: 32px;
    padding-right: 111px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 86px;
      background-color: #d9d9d9;
    }
  }

  .txt {
    margin: 0;
  }

  .link {
    color: #000;
  }
}

.container:last-child {
  padding: 22px 0 35px 0;

  .logoWrapper {
    padding: 0 52px 0 53px;
  }
}

@media (max-width: 860px) {
  .container {
    flex-direction: column;
    padding: 48px 0 24px 0;

    .content {
      padding-top: 24px;

      &::before {
        top: 0;
        width: 98%;
        height: 1px;
      }
    }

    .txt {
      font-size: 14px;
      line-height: 21px;
    }

    .logoWrapper {
      padding: 0 0 48px 0;
    }
  }

  .container:first-child {
    .logo {
      width: 159px;
      height: 44px;
    }
  }

  .container:last-child {
    padding: 48px 0 24px 0;

    .logoWrapper {
      padding: 0 0 48px 0;
    }

    .logo {
      width: 152px;
      height: 42px;
    }
  }
}
