.header {
  display: flex;
  padding: 39px 60px 0 80px;
  justify-content: space-between;

  .btn {
    padding: 10px 35px 10px 35px;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Poppins Medium", sans-serif;
    cursor: pointer;
    color: #000;
    border-radius: 2px;
    border: none;
  }
}

@media (max-width: 820px) {
  .header {
    padding: 34px 24px 8px 24px;

    .btn {
      padding: 0 15px 0 15px;
      font-size: 14px;
      height: 32px;
    }

    .logo {
      width: 100px;
      height: 22px;
    }
  }
}
