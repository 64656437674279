.firstPart {
  display: flex;
  gap: 50px;
  width: 100%;
  padding-bottom: 30px;

  .name {
    font-size: 16px;
    line-height: 22.4px;
    font-family: "Poppins SemiBold", sans-serif;
  }

  .txt {
    width: 100%;
    max-width: 864px;
    line-height: 22.4px;
    font-family: 'Poppins Regular', sans-serif;
    font-size: 16px;
  }
}

.secondPart {
  display: flex;
  flex-direction: row-reverse;
  gap: 75px;

  .name {
    font-size: 16px;
    line-height: 22.4px;
    font-family: "Poppins SemiBold", sans-serif;
  }

  .txt {
    line-height: 22.4px;
    font-family: 'Poppins Regular', sans-serif;
    font-size: 16px;
  }
}

.imgWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 64px;
  margin-top: 44px;

  .img {
    filter: grayscale(2.2);
  }

  .imgMobile {
    display: none;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    h2 {
      padding: 0 15px 0 15px
    }
  }

  .firstPart,
  .secondPart {
    width: unset;
    padding: 0 15px 0 15px
  }
}

@media (max-width: 820px) {
  .firstPart {
    padding: 32px 15px 32px 15px;
    flex-direction: column-reverse;
    gap: unset;

    .txt,
    .name {
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  .secondPart {
    gap: 0;
    flex-direction: column-reverse;
    .txt,
    .name {
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  .imgWrapper {
    margin-bottom: 64px;
    margin-top: 24px;
  }

  .wImg,
  .aImg {
    height: 76px;
  }

  .wImg {
    width: 90px;
  }

  .aImg {
    width: 70px;
  }
}

@media(max-width: 650px) {
  .imgWrapper {
    .img {
      display: none;
    }

    .imgMobile {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}