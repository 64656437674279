.hero {
  background: linear-gradient(256.54deg, #110C13 22.19%, #4F1222 45.99%, #26424E 84.9%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  height: 667px;

  .mainInfo {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;

    .title {
      font-size: 64px;
      font-family: "Playfair Display Bold", sans-serif;
      line-height: 70.4px;
      padding-bottom: 24px;
    }

    .title,
    .description,
    .shortInfo {
      margin: 0;
    }

    .description {
      font-size: 24px;
      line-height: 26.4px;
      padding-bottom: 48px;
    }

    .shortInfo {
      font-size: 16px;
      text-transform: uppercase;
      font-family: "Poppins Regular", sans-serif;
      letter-spacing: 4px;
    }

    .heroImage {
      padding-bottom: 35px;
    }
  }
}

@media(max-width: 820px) {
  .hero {
    position: relative;
    .heroImage {

      width: 192px;
    }
    .mainInfo {
      padding: 0 32px;
      width: unset;
      text-align: center;

      .title {
        font-size: 40px;
        line-height: 44px;
        padding-bottom: 24px;
      }

      .description {
        font-size: 16px;
        line-height: 17.6px;
        padding-bottom: 70px;
      }

      .shortInfo {
        line-height: 13.2px;
        font-size: 12px;
        position: absolute;
        bottom: 24px;
      }
    }
  }
}