.servicesWrapper {
  h2 {
    padding-top: 32px;
  }
  .services {
    margin-top: 32px;
    margin-bottom: 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}


@media(max-width: 1200px) {
  .servicesWrapper {
    .services,
    h2 {
      padding: 0 15px;
    }
  }
}