.overlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  z-index: 45;

  .body {
    display: flex;
    background-color: #282b35;
    flex-direction: column;
    position: relative;
    z-index: 80;
    width: 720px;
    height: 417px;
    box-shadow: 0px 2px 8px 0px #0000000d;
    padding: 16px 32px 24px 32px;

    .titleWrapper {
      display: flex;
      padding-bottom: 65px;
      justify-content: space-between;

      .title {
        margin: 0;
        font-size: 32px;
        line-height: 44.8px;
        color: #fff;
        font-family: "Playfair Display SemiBold", sans-serif;
      }

      .btn {
        all: unset;
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }

    .contentWrapper {
      padding-bottom: 65px;

      .tg,
      .email {
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        margin-bottom: 5px;
        display: block;
      }

      .tg {
        .tgLink {
          font-size: 20px;
          line-height: 28px;
          color: #fff;
        }
      }

      .email {
        display: block;

        .url {
          font-size: 20px;
          line-height: 28px;
          color: #fff;
        }
      }
    }

    .mobileContent {
      display: none;
    }

    .based {
      padding-bottom: 59px;

      span {
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        margin-bottom: 5px;
        display: block;
      }
    }

    .btnWrapper {
      display: flex;
      justify-content: flex-end;

      .closeBtn {
        cursor: pointer;
        all: unset;
        border: 1px solid #fff;
        border-radius: 2px;
        width: 115px;
        height: 48px;
        font-size: 16px;
        color: #fff;
        font-family: "Poppins Medium", sans-serif;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.background {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 46;
  margin: 0;
  padding: 0;
}

@media (max-width: 820px) {
  .overlay {
    .body {
      height: 100vh;
      width: 100vw;

      .based {
        position: absolute;
        bottom: 100px;
        padding-bottom: unset;

        span {
          font-size: 14px;
          margin-bottom: unset;
        }
      }

      .titleWrapper {
        margin-top: 183px;
        margin-bottom: 48px;
        padding-bottom: unset;

        .btn {
          top: 50px;
          right: 24px;
          position: absolute;
          width: 25px;
          height: 25px;

          img {
            width: 25px;
            height: 25px;
          }
        }
      }

      .contentWrapper {
        display: none;
      }

      .mobileContent {
        display: flex;
        flex-direction: column;

        .tgLinkMobile,
        .mailMobile {
          position: relative;
          font-family: "Poppins Medium", sans-serif;
          border: 1px solid #ffffff40;
          font-size: 14px;
          line-height: 19.6px;
          text-align: center;
          padding: 10px;
          color: #fff;
          margin-bottom: 32px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .tgIcon,
          .mailIcon {
            position: absolute;
            left: 12px;
          }
        }
      }

      .btnWrapper {
        display: none;
      }
    }
  }
}
