
.partnersWrapper {
  h2 {
    padding-top: 32px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 100px;
    margin-top: 32px;
  }
}


@media(max-width: 1200px) {
  .partnersWrapper {
    padding: 0 15px;

    .wrapper {
      margin-top: 32px;
    }
  }
}