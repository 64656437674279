body {
  margin: 0;
  font-family: "Poppins Light", sans-serif;
  overflow-x: hidden;
  background-color: #FAFAFA;
}

/*Poppins*/

@font-face {
  font-family: "Poppins Light";
  src: url("fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Regular";
  src: url("fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/*Playfair Display*/

@font-face {
  font-family: "Playfair Regular";
  src: url("fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display Bold";
  src: url("fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display SemiBold";
  src: url("fonts/Playfair_Display/static/PlayfairDisplay-SemiBold.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
